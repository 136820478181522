<template>
	<div>
		<StageIntroDialog />

		<v-card v-if="questionnaire && !is_fetching" flat class="wr_grey_1">
			<v-card-text v-if="!isQuestionnaireDrafted">
				<!-- Answers given -->
				<FormResult></FormResult>

				<!-- Comparison that how far user has come -->
				<PlanningComparison />
			</v-card-text>

			<v-card-text v-else>
				<!-- Starting description -->
				<ModuleIllustration></ModuleIllustration>

				<!-- Summary Questions -->
				<v-card-text>
					<v-form :ref="form_ref">
						<FormGenerator></FormGenerator>
					</v-form>
				</v-card-text>

				<!-- Finish description -->
				<v-card-text class="pt-0">
					<v-card-text class="font-weight-bold black--text subtitle-1 pt-0">{{
						$t("app.are_you_ready_to_continue")
					}}</v-card-text>
					<v-card-actions>
						<AppButton
							label="app.okay"
							color="greenAccent2"
							@click="submitAndProceed()"
						></AppButton>
					</v-card-actions>
				</v-card-text>
			</v-card-text>

			<!-- Show finish source documentation  -->
			<StageFinishDialog
				v-if="dialog"
				v-model="dialog"
				:dialog="dialog"
				current_step="app.choose_theme.summary.title"
				@close="dialog = false"
			></StageFinishDialog>
		</v-card>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { formValidationMixin } from "@/mixins/questionnaire/formValidaionMixin.js";
import FormResult from "@/components/plugins/formResult/Index";
import FormGenerator from "@/components/plugins/formGenerator/Index";
import PlanningComparison from "@/components/research/modules/_common/PlanningComparison.vue";
import ModuleIllustration from "@/components/research/modules/_common/summary/ModuleIllustration.vue";
import moment from "moment";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { accessManagementMixin } from "@/mixins/accessManagementMixin";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";

export default {
	name: "ModuleSummary",

	mixins: [formValidationMixin, accessManagementMixin],

	props: {
		form_ref: {
			required: true,
		},
		questionnaire_name: {
			required: true,
		},
	},

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		ModuleIllustration,
		FormGenerator,
		FormResult,
		PlanningComparison,
		StageFinishDialog,
		StageIntroDialog,
	},

	computed: {
		...mapState({
			current_module: (state) => state.research.current_module,
		}),
	},

	created() {
		this.readyToListenEvents();
		this.init();
	},

	methods: {
		async readyToListenEvents() {
			this.$eventBus.$on(
				`m${this.current_module}-finish-summary-questionnaire`,
				(payload) => {
					this.dialog = true;
				},
			);
		},

		async init() {
			try {
				this.$loader.start();
				// Get user's plan
				await this.getUserPlan();
				// Get questions of requested questionnaire
				await this.getQuestions(this.questionnaire_name);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getUserPlan() {
			await this.$store.dispatch("planAndManage/getUserPlan");
		},

		async submitAndProceed() {
			try {
				this.$loader.start();

				// Submit questionnaire
				await this.submitQuestionnaire(this.form_ref);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off(`m${this.current_module}-finish-summary-questionnaire`);
	},
};
</script>
