<template>
	<v-card-text class="pt-0">
		<!-- Image -->
		<v-card-text class="pt-0">
			<v-img
				:src="require('@/assets/images/finish-line.png')"
				width="80"
			></v-img>
		</v-card-text>

		<!-- Text -->
		<v-card-text class="display-1 font-weight-bold oflm-wr">
			{{ $t("app.congratulations") }}<br />
			{{
				$t("app.you_have_finished_work_on_module", {
					current_module: moduleNumber,
				})
			}}
		</v-card-text>

		<v-card-text class="subtitle-1 black--text pt-0">
			{{ $t("app.take_a_look_accomplished") }}
		</v-card-text>

		<v-card-text class="pt-0" v-if="points.length">
			<v-row
				no-gutters
				v-for="(point, index) in points"
				:key="index"
				class="mt-2"
			>
				<v-btn fab color="amberDarken1" class="elevation-0 completed-btn-wr">
					<v-icon small>$vuetify.icons.values.check</v-icon>
				</v-btn>
				<span class="mx-3 pt-1 font-weight-bold greyDarken4--text subtitle-1">
					{{ $t(point) }}
				</span>
			</v-row>
		</v-card-text>

		<v-card-text class="subtitle-1 black--text">
			{{ $t(`app.M${moduleNumber}.you_done_and_feel_proud`) }}
		</v-card-text>

		<v-divider class="greyLighten2"></v-divider>
	</v-card-text>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "ModulesSummaryCommonInterface",

	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
		}),

		moduleNumber() {
			return Number(this.current_stage.toString()[0]);
		},

		points() {
			switch (this.moduleNumber) {
				case 2:
					return [
						"app.define_question.summary.point_1.title",
						"app.define_question.summary.point_2.title",
						"app.define_question.summary.point_3.title",
						"app.define_question.summary.point_4.title",
						"app.define_question.summary.point_5.title",
					];
				case 3:
					return [
						"app.gather_sources.summary.point_1.title",
						"app.gather_sources.summary.point_2.title",
						"app.gather_sources.summary.point_3.title",
						"app.gather_sources.summary.point_4.title",
						"app.gather_sources.summary.point_5.title",
						"app.gather_sources.summary.point_6.title",
					];
				case 4:
					return [
						"app.write_research.summary.point_1.title",
						"app.write_research.summary.point_2.title",
						"app.write_research.summary.point_3.title",
						"app.write_research.summary.point_4.title",
						"app.write_research.summary.point_5.title",
					];
				case 5:
					return [
						"app.reflect_journey.summary.point_1.title",
						"app.reflect_journey.summary.point_2.title",
						"app.reflect_journey.summary.point_3.title",
						"app.reflect_journey.summary.point_4.title",
						"app.reflect_journey.summary.point_4.title",
					];
				case 6:
					return [
						"app.present.summary.point_1.title",
						"app.present.summary.point_2.title",
						"app.present.summary.point_3.title",
						"app.present.summary.point_4.title",
						"app.present.summary.point_5.title",
					];
				default:
					return [];
			}
		},
	},
};
</script>

<style scoped>
.completed-btn-wr {
	width: 24px !important;
	height: 24px !important;
}
</style>
